import { User } from 'oidc-client-ts';

import { getCookie } from 'context/AnalyticsProvider';
import { tokenKey, userIdKey } from 'context/AuthProvider';
import getOS from 'libs/platform';
import { getConsent, REFERRER_SESSION_KEY, UTM_SESSION_KEY } from './analytics';

export function getUser() {
	const oidcStorage = localStorage.getItem(
		`oidc.user:${process.env.GATSBY_KEYCLOAK_URL}/realms/${process.env.GATSBY_KEYCLOAK_REALM}:${process.env.GATSBY_KEYCLOAK_CLIENTID}`
	);
	if (!oidcStorage) {
		return null;
	}

	return User.fromStorageString(oidcStorage);
}

export default function setupHeaders(headers = {}) {
	if (typeof window === 'undefined') return headers;

	const os = getOS();
	headers.os = os?.name;

	headers.userAgent = navigator.userAgent;

	headers.url = window.location.href;

	headers.ip = sessionStorage.getItem('nte-ip') || undefined;

	const utmParams = sessionStorage.getItem(UTM_SESSION_KEY);

	headers.utmParams =
		utmParams ||
		JSON.stringify({
			source: 'direct',
			medium: 'none',
			name: 'not set',
			content: 'not set',
		});

	headers.fbp = getCookie('_fbp');
	headers.fbc = getCookie('_fbc');
	headers.gclid = getCookie('_gcl_aw');
	headers.gtag = getCookie('_ga')?.substring(6);

	headers.referrer =
		sessionStorage.getItem(REFERRER_SESSION_KEY) || document.referrer;

	headers.userId =
		localStorage.getItem(userIdKey) || getCookie('ajs_user_id');
	headers.anonymousId = getCookie('ajs_anonymous_id');

	headers.consent = JSON.stringify(getConsent());

	if (headers?.authorization || headers?.Authorization) return headers;

	let token = null;

	if (!token) {
		const user = getUser();
		if (user) {
			token = user.access_token;
		}
	}

	if (!token) token = sessionStorage.getItem(tokenKey);

	if (!token) {
		return headers;
	}

	return {
		...headers,
		authorization: token ? `Bearer ${token}` : '',
	};
}
