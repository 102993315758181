import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';

import Link from 'components/Link';
import MaxWidth from '../layouts/max-width';

const List = styled.ul`
	margin: 0;
	padding: 0;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

const ListItem = styled.li`
	list-style: none;
	display: inline;
	font-size: 17px;
	line-height: 24px;
	${p =>
		p.theme.media.smallOnly(css`
			font-size: 14px;
			line-height: 22px;
		`)}
`;

const StyledLink = styled(Link)`
	font-size: inherit;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
`;

const Seperator = styled.span`
	margin: 0 10px;
	${p =>
		p.theme.media.smallOnly(css`
			margin: 0 7.5px;
		`)}
	svg {
		vertical-align: middle !important;
		margin-bottom: 2px;
		display: inline-block;
		height: 22px;
		width: 8px;
		${p =>
			p.theme.media.smallOnly(css`
				width: 6px;
			`)}
	}
`;

const Span = styled.span`
	font-size: inherit;
	text-decoration: none;
	transition: all 0.2s;
	font-weight: ${p => (p.$current && '500') || '400'};
	color: ${p =>
		(!p.$current && p.theme.colors.grey700) || p.theme.colors.grey900};
	display: inline;
`;

const Wrapper = styled.div`
	width: 100%;
	padding: 15px 0;
	background-color: ${p =>
		(p.$transparentheader === 'true' && 'transparent') ||
		p.theme.colors.white};
	font-size: 17px;
	line-height: 24px;
	${p =>
		p.theme.media.smallOnly(css`
			padding: 10px 0;
		`)}

	${p =>
		(p.$transparentheader === 'true' &&
			css`
				z-index: 2;
				position: absolute;
				top: 100px;
				${p =>
					p.theme.media.smallOnly(css`
						top: 80px;
					`)}
			`) ||
		css`
			border-top: 1px solid ${p.theme.colors.grey300};
			border-bottom: 1px solid ${p.theme.colors.grey300};
		`}
		

	${StyledLink} {
		color: ${p =>
			(p.$transparentheader === 'true' &&
				p.$headertextcolor === 'white' &&
				p.theme.colors.blue200) ||
			p.theme.colors.grey700} !important;
		&:hover {
			color: ${p =>
				(p.$transparentheader === 'true' &&
					p.$headertextcolor === 'white' &&
					p.theme.colors.white) ||
				p.theme.colors.blue800} !important;
		}
	}
	${Span} {
		color: ${p =>
			(p.$transparentheader === 'true' &&
				p.$headertextcolor === 'white' &&
				p.theme.colors.blue200) ||
			p.theme.colors.grey900};
	}
	${Seperator} svg {
		color: ${p =>
			(p.$transparentheader === 'true' &&
				p.$headertextcolor === 'white' &&
				p.theme.colors.blue200) ||
			p.theme.colors.grey700} !important;
	}
`;

/**
 * Breadcrumbs component
 * @param {object} props
 * @param {object} props.data
 * @param {object} props.location
 * @param {string} props.currentTitle
 * @param {object} props.overrideParent
 * @param {boolean} props.dark
 * @param {boolean} props.border
 * @param {object} props.style
 * @returns {JSX.Element}
 */
export default function Breadcrumbs({
	data,
	location,
	currentTitle,
	overrideParent,
	pagesettings = {},
	style,
}) {
	const crumbs = createCrumbs({
		crumbs: data?.crumbs,
		location,
		currentTitle,
	});

	// Paths to be skipped in crumb trail
	const pathExcludes = ['a', 'b', 'c', 'bestill'];

	// Paths to be shown as <span> instead of <a>
	const spanCrumbs = ['avtaler', 'kampanje', 'tilbakemelding'];

	if (!crumbs.length) return null;

	return (
		<Wrapper
			style={style}
			$transparentheader={pagesettings?.transparentheader}
			$headertextcolor={pagesettings?.headertextcolor}>
			<MaxWidth>
				<nav aria-label="Brødsmulesti" role="navigation">
					<List>
						{crumbs.map((crumb, i) => {
							// If path is in pathExcludes-array then return null
							if (pathExcludes?.includes(crumb.title)) {
								return null;
							}

							// If is set to override parent pathname or title then do so
							if (i === crumbs?.length - 2) {
								if (overrideParent?.pathname) {
									crumb.pathname = overrideParent.pathname;
								}
								if (overrideParent?.title) {
									crumb.title = overrideParent.title;
								}
							}

							// Capitalize first letter of title
							const title = capitalize(
								pathIntoNorwegianWord(crumb?.title)
							);

							// Lowercase title
							const titleLowerCase = crumb?.title?.toLowerCase();

							return (
								<ListItem key={crumb + '-' + i}>
									<>
										{/** If title matches paths to be shown as span instead of anchor */}
										{(spanCrumbs?.includes(
											titleLowerCase
										) && (
											<Span $current={false}>
												{title}
											</Span>
										)) || (
											<>
												{/** If is last crumb then show <span> instead of <a> */}
												{crumbs.length === i + 1 ? (
													<Span
														aria-current="page"
														$current={true}>
														{title}
													</Span>
												) : (
													<StyledLink
														to={crumb?.pathname}
														aria-current={
															(crumb?.current &&
																'page') ||
															'false'
														}
														title={title}>
														{title}
													</StyledLink>
												)}
											</>
										)}
										{i + 1 !== crumbs.length && (
											<Seperator>
												<FontAwesomeIcon
													icon={faAngleRight}
													size="xs"
												/>
											</Seperator>
										)}
									</>
								</ListItem>
							);
						})}
					</List>
				</nav>
			</MaxWidth>
		</Wrapper>
	);
}

/**
 * Create crumbs from data
 * @param {object} data
 * @param {array} data.crumbs
 * @param {object} location
 * @param {string} currentTitle
 * @returns {array}
 */
function createCrumbs({ crumbs = [], location = {}, currentTitle = '' }) {
	if (!crumbs?.length) return [];

	return crumbs?.map(crumb => {
		// Replace dashes with spaces
		let title = crumb?.crumbLabel?.replace(/-/g, ' ');

		// If current crumb is the same as current page and has currentTitle then set title to currentTitle
		if (
			crumb?.pathname?.indexOf(location?.pathname) !== -1 &&
			!!currentTitle
		) {
			title = currentTitle;
		}

		return {
			pathname: crumb?.pathname,
			title,
			current: crumb?.pathname === location?.pathname,
		};
	});
}

/**
 * Replace pathnames with norwegian words
 * @param {string} pathname
 * @returns {string}
 */
function pathIntoNorwegianWord(pathname) {
	let paths = {
		strom: 'strøm',
		stromavtaler: 'strømavtaler',
		strombestilling: 'strømbestilling',
		'strom og energitjenester': 'strøm og energitjenester',
		bredband: 'bredbånd',
		bredbandstjenester: 'bredbåndstjenester',
		'energilosninger til borettslag': 'energiløsninger til borettslag',
		naeringsservice: 'næringsservice',
		naeringskunder: 'næringskunder',
		'naermiljoet mitt': 'nærmiljoet mitt',
		baerekraft: 'bærekraft',
		'baerekraft i NTE': 'bærekraft i NTE',
		arsrapport: 'årsrapport',
		'for leverandorer': 'for leverandører',
		'altibox flex s': 'Altibox Flex S',
		'altibox flex m': 'Altibox Flex M',
		'altibox flex l': 'Altibox Flex L',
		'kanaler og strommetjenester': 'Kanaler og strømmetjenester',
		'solenergi til naeringsbygg': 'Solenergi til næringsbygg',
		'sok om midler': 'Søk om midler',
	};
	if (!paths[pathname]) return pathname;
	return paths[pathname];
}

/**
 * Capitalize first letter of string
 * @param {string} s
 * @returns {string}
 */
function capitalize(s) {
	if (typeof s !== 'string') return '';
	return s.charAt(0).toUpperCase() + s.slice(1);
}
