import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

import { track } from 'context/AnalyticsProvider';
import { getUrl, isExternalUrl } from 'libs/content';

export default function Link({ children, ...props }) {
	let label = props?.title;
	if (!label && !children?.type) {
		label = children;
	}

	const to = getUrl(props?.to || props?.href);

	return (
		<GatsbyLink
			tabIndex="0"
			onClick={() => {
				track('Button Clicked', {
					label: label,
					category:
						props?.track?.category || window.location.pathname,
					href: to,
				});
			}}
			onKeyDown={() => {
				track('Button Clicked', {
					label: label,
					category:
						props?.track?.category || window.location.pathname,
					href: to,
				});
			}}
			{...props}
			to={to}>
			{children}
		</GatsbyLink>
	);
}

export function AnchorLink({ children, tracking = true, ...props }) {
	let label = props?.title;
	if (!label && !children?.type) {
		label = children;
	}

	let hasTracked = false;

	return (
		<a
			role="button"
			tabIndex="0"
			onClick={() => {
				if (tracking && !hasTracked) {
					track('Button Clicked', {
						label: label,
						category:
							props?.track?.category || window.location.pathname,
						href: props?.href,
					});
				}
				hasTracked = true;
				if (props?.onClick) {
					props.onClick();
				}
			}}
			onKeyDown={() => {
				if (tracking) {
					track('Button Clicked', {
						label: label,
						category:
							props?.track?.category || window.location.pathname,
						href: props?.href,
					});
				}
				if (props?.onClick) {
					props.onClick();
				}
			}}
			{...props}
			targetblank={undefined}>
			{children}
		</a>
	);
}

/**
 * Represents an conditional link, if not clickable it will render a div
 * @param {boolean} clickable - Flag to indicate if the link is clickable
 * @param {React.ReactNode} children - The children to display
 * @param {...object} props - The parameters for the component
 */
export function ConditionalLink({ clickable = 'false', children, ...props }) {
	if (!props?.to || clickable === 'false') {
		return <div>{children}</div>;
	}

	if (isExternalUrl(props?.to)) {
		return (
			<AnchorLink
				href={props?.to}
				target="_blank"
				rel="noreferrer"
				tracking={true}
				title={props?.title}
				className="link">
				{children}
			</AnchorLink>
		);
	}

	return (
		<Link {...props} to={getUrl(props?.to || props?.href)} className="link">
			{children}
		</Link>
	);
}
