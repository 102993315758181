import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

export default function Portal({ children, className }) {
	const mount = document.getElementById('wrapper');
	const el = useMemo(() => {
		const div = document.createElement('div');
		if (className) {
			div.className = className;
		}
		return div;
	}, [className]);

	useEffect(() => {
		mount.appendChild(el);
		return () => mount.removeChild(el);
	}, [el, mount]);

	return createPortal(children, el);
}
