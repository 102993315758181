import { useApolloClient } from '@apollo/client';
import { AnalyticsBrowser } from '@segment/analytics-next';
import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import {
	UPDATE_INTERCOM_CONTACT,
	TRACK_CONSENT_CHANGES,
} from 'components/consent-portal/calls';
import { getConsent, setupContext } from 'libs/analytics';
import { getUrlCategory } from 'libs/content';
import getOS from 'libs/platform';
import setupHeaders from 'libs/setup-headers';

export function getCookie(cookie) {
	const name = `${cookie}=`;
	const decodedCookie =
		typeof document !== 'undefined'
			? decodeURIComponent(document.cookie)
			: '';
	const ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

export function getUserId() {
	return getCookie('ajs_user_id');
}

function getRandomString(length) {
	return Math.random()
		.toString(36)
		.substring(2, length + 2);
}

export function getAnonymousId() {
	let anonId = getCookie('ajs_anonymous_id');
	if (!anonId) anonId = getCookie('backup_anonymous_id');
	if (!anonId && typeof document !== 'undefined') {
		anonId = `${getRandomString(8)}-back-${getRandomString(
			4
		)}-${getRandomString(4)}-${getRandomString(12)}`;
		document.cookie = `backup_anonymous_id=${anonId}; path=/; max-age=3600; SameSite=Lax`;
	}
	return anonId;
}

export function removeQueryParam(paramKey) {
	const url = new URL(window.location.href);
	const searchParams = new URLSearchParams(url.search);
	searchParams.delete(paramKey);
	url.search = searchParams.toString();

	const newUrl = url.href;

	window.history.replaceState(null, '', newUrl);
}

export function delay(ms = 1000) {
	return new Promise(resolve => {
		setTimeout(resolve, ms);
	});
}

export async function track(event = '', properties = {}) {
	try {
		if (!event) {
			throw new Error('event missing');
		}
		if (!properties.Platform) {
			properties.Platform = 'nte.no';
		}

		await window.analytics.track(event, properties);
	} catch (error) {
		console.log('Failed to track event', error);
	}
}

export async function trackBackEnd(event, properties = {}, method = undefined) {
	if (!event) return;

	const userId = getUserId() || undefined;
	const anonymousId = getAnonymousId() || undefined;
	const category = getUrlCategory(window?.location?.pathname);
	const context = setupContext();

	if (properties?.traits) {
		context.traits = { ...properties.traits };
		properties.traits = undefined;
	}

	try {
		return await fetch(`${process.env.GATSBY_API_BASE_URL}status/update`, {
			method: 'POST',
			headers: setupHeaders({
				'Content-Type': 'application/json',
			}),
			body: JSON.stringify({
				event,
				context,
				properties: {
					...properties,
					category,
					entry: properties?.entry || document?.title,
					currentUrl: window?.location?.href,
				},
				userId,
				anonymousId,
				method,
				os: getOS(),
				timestamp: new Date(),
			}),
		}).then(trackingResponse => {
			if (!trackingResponse.ok) {
				console.log(trackingResponse);
				throw new Error(
					`Tracking backend failed, ${trackingResponse.status}: ${trackingResponse.statusText}`
				);
			}
		});
	} catch (error) {
		console.log(error);
		return error;
	}
}

const AnalyticsContext = createContext();
export function useAnalytics() {
	return useContext(AnalyticsContext);
}

export default function AnalyticsProvider({ children }) {
	const [ready, setReady] = useState(false);
	const [intercomReady, setIntercomReady] = useState(false);
	const onConsentChangeHasRun = useRef(false);

	const client = useApolloClient();

	useMemo(() => {
		if (typeof window === 'undefined') return;

		window.analytics = new AnalyticsBrowser();

		window.analytics.addSourceMiddleware(({ payload, next }) => {
			const context = setupContext(payload.obj.context);
			payload.obj.context = context;

			payload.obj.context.consent.categoryPreferences = getConsent();
			next(payload);
		});
	}, []);

	useEffect(() => {
		window.addEventListener('analyticsLoaded', handleAnalyticsReady);
		window.addEventListener('CookieInformationLoaded', storeConsent);
		window.addEventListener(
			'CookieInformationConsentGiven',
			onConsentChange
		);

		document.addEventListener('click', handleCookieDetailsClick);
		document.addEventListener('click', handleCookieModalLinkClick);

		return () => {
			window.removeEventListener('analyticsLoaded', handleAnalyticsReady);
			window.removeEventListener('CookieInformationLoaded', storeConsent);
			window.removeEventListener(
				'CookieInformationConsentGiven',
				onConsentChange
			);

			document.removeEventListener('click', handleCookieDetailsClick);
			document.removeEventListener('click', handleCookieModalLinkClick);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useMemo(async () => {
		if (!ready) return;

		const urlParams = new URLSearchParams(window.location.search);
		const userId = urlParams.get('id') || urlParams.get('ID');

		if (userId) {
			removeQueryParam('id');
			removeQueryParam('ID');
			window.CookieInformation.submitAllCategories();

			window.analytics.identify(userId);
		}

		// If url contains read-about-cookies then open cookie modal and read more section
		const readAboutCookies = urlParams.get('read-about-cookies');
		if (readAboutCookies) {
			const cookieModal = document?.querySelector('#coiConsentBanner');

			showCookieConsentModal();
			setTimeout(() => {
				const readMoreLink = document?.getElementById('coiShowDetails');
				if (window?.toggleDetails && cookieModal && readMoreLink) {
					readMoreLink?.click();
				}
			}, 1000);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ready]);

	const handleAnalyticsReady = () => {
		setReady(true);

		if (window.Intercom) {
			window.Intercom('shutdown');
		}
		setTimeout(() => {
			setIntercomSettings();
		}, 3000);
	};

	async function setIntercomSettings(userId = null) {
		if (!window.Intercom) {
			setIntercomReady(false);
			return null;
		}

		const currentUserId = userId || getUserId();

		if (
			currentUserId.includes('==') &&
			!window.intercomSettings.user_hash
		) {
			await fetch(
				`${process.env.GATSBY_API_BASE_URL}status/intercom-settings`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						user_id: currentUserId,
					},
				}
			)
				.then(response => response.json())
				.then(async data => {
					if (!data.userHash)
						throw new Error("User hash doesn't exist");

					window.intercomSettings = {
						api_base: 'https://api-iam.eu.intercom.io',
						app_id: process.env.GATSBY_INTERCOM_MESSENGER_APP_ID,
						user_id: getUserId(),
						user_hash: data.userHash,
					};
				})
				.catch(() => {
					console.log('Skipping intercom settings');
				});
		}

		window.Intercom('boot');
		setIntercomReady(true);
	}

	const storeConsent = () => {
		window.analytics
			.load(
				{
					writeKey: process.env.GATSBY_SEGMENT_PRODUCTION_WRITE_KEY,
					disable:
						process.env.GATSBY_SEGMENT_DISABLE === 'true' ||
						process.env.GATSBY_ENV_TESTING === 'true',
					cdnURL: 'https://d29kdjimk0edzh.cloudfront.net',
				},
				{
					integrations: {
						'Segment.io': {
							apiHost: 'd1b8z1cuz1lmzj.cloudfront.net/v1',
						},
					},
				}
			)
			.then(() => {
				window.dispatchEvent(new Event('analyticsLoaded'));

				window.analytics.page(document?.title);
			})
			.catch(error => {
				console.error('analytics load', error.message);
			});
		window.currentConsent = { ...window?.cicc };
	};

	const onConsentChange = async () => {
		if (!window.CookieInformation) return;

		if (!onConsentChangeHasRun.current) {
			onConsentChangeHasRun.current = true;
			return;
		}

		const consent = {
			tekniske: true,
			funksjonelle: window?.cicc?.cookie_cat_functional || false,
			statistikk: window?.cicc?.cookie_cat_statistic || false,
			markedsforing: window?.cicc?.cookie_cat_marketing || false,
		};

		await client.mutate({
			mutation: TRACK_CONSENT_CHANGES,
			variables: {
				consent,
			},
		});
	};

	// If the cookie consent div is expanded then add class to body to prevent scrolling behind modal
	const handleCookieDetailsClick = () => {
		const cookieDetailsDiv = document?.querySelector('#coiConsentBanner');
		if (!cookieDetailsDiv) return;

		setTimeout(() => {
			if (cookieDetailsDiv?.offsetHeight > 10) {
				document?.body?.classList?.add('modal-open');
			} else {
				document?.body?.classList?.remove('modal-open');
			}
		}, 550);
	};

	// Detect click and if it is a link with a specific hash then open cookie-modal
	const handleCookieModalLinkClick = e => {
		if (!e?.target?.href?.includes('#vis-informasjonskapsler')) return;
		e.preventDefault();
		showCookieConsentModal();
	};

	async function identify({ userId = '', ...props }) {
		try {
			const _userId = userId || getUserId();

			if (!_userId) return;

			// Track user attributes
			if (props.email || props.phone) {
				await client
					.mutate({
						mutation: UPDATE_INTERCOM_CONTACT,
						variables: {
							userObject: {
								email: props.email,
								phone: props.phone,
							},
						},
					})
					.catch(error => {
						console.error(
							`identifyUser updateInterc: ${error.message}`
						);
					});
			}

			await window.analytics.identify(_userId, {
				...props,
			});

			// Set user token for Algolia insights
			if (window?.aa) window.aa('setUserToken', _userId);
		} catch (error) {
			console.log('Failed to track identify', error);
		}
	}

	return (
		<AnalyticsContext.Provider
			value={{
				ready,
				identify,
				intercomReady,
				cookieConsentHasRun: onConsentChangeHasRun?.current,
			}}>
			{children}
		</AnalyticsContext.Provider>
	);
}

/**
 * Tracks a Google Ads conversion event.
 *
 * @function trackGoogleAdsConversion
 * @param {Object} options - Options for tracking the conversion.
 * @param {string} options.send_to - The Google Ads conversion ID to which the event will be sent.
 * @param {number} options.value - The value associated with the conversion (optional, default: 1000).
 * @param {string} options.transaction_id - The ID of the transaction associated with the conversion.
 * @returns {Promise<void>} - A Promise that resolves when the tracking is completed.
 */
export function trackGoogleAdsConversion({
	send_to = '',
	value = 1000,
	transaction_id = '',
	...rest
}) {
	if (
		!window?.cicc?.cookie_cat_marketing ||
		!window?.gtag ||
		!send_to ||
		!transaction_id
	)
		return;

	window.gtag('event', 'conversion', {
		send_to,
		value,
		transaction_id,
		currency: 'NOK',
		...rest,
	});

	console.log('Tracked Google Adwords conversion');

	return;
}

/**
 * Show Cookie Consent Modal
 *
 * This function is responsible for displaying the cookie consent modal by modifying its style properties and attributes.
 * It checks if the necessary elements exist and if the `CookieInformation` object is defined. If not, it logs an error and exits.
 * If all conditions are met, it displays the cookie consent modal by setting its display style to 'block', setting `aria-hidden` to 'false',
 * and giving it focus.
 */
export function showCookieConsentModal() {
	const cookieModal = document.getElementById('coiConsentBanner');
	if (!window?.CookieInformation) {
		console.error('CookieInformation not defined');
		return;
	}

	if (!cookieModal) {
		console.error('No modal with id "coiConsentBanner" found');
		return;
	}

	cookieModal.style.display = 'block';
	cookieModal.setAttribute('aria-hidden', 'false');
	cookieModal.focus();
}

/**
 * Hook that hides the Intercom chat button if the page has the chat button hidden.
 * @param {boolean} ready - Indicates if the analytics provider is ready.
 * @param {boolean} hideChatButton - Indicates if the chat button should be hidden.
 * @returns {void}
 */
export const useIntercomLauncher = ({ ready, hideChatButton = true }) => {
	useEffect(() => {
		if (!ready || !window.Intercom) return;

		// Define whether to hide the chat button or not
		window.Intercom('update', {
			hide_default_launcher: hideChatButton,
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ready, hideChatButton]);
};
